import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploads: [],
  selectedUpload: null
};


export const uploadsSlice = createSlice({
  name: 'uploads',
  initialState,
  
  reducers: {
    setUploads: (state, action) => {
      state.uploads = action.payload;
    },
    addUpload: (state, action) => {
        state.uploads.unshift(action.payload);
    }    
  }
});

export const {setUploads, addUpload} = uploadsSlice.actions;

export const selectUploads = (state) => state.uploads.uploads;

export default uploadsSlice.reducer;
