import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import uploadsReducer from '../features/bomScheduler/UploadsSlice';


export const store = configureStore({
  reducer: {
    counter: counterReducer,
    uploads: uploadsReducer
  },
});
