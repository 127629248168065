import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

/**
 * Acquires an access token silently, and if necessary, via popup interaction.
 * @param {PublicClientApplication} instance - The MSAL PublicClientApplication instance.
 * @param {Array} accounts - The array of user accounts.
 * @returns {Promise<string|null>} - A promise that resolves to the access token or null.
 */
export const getToken = async (instance, accounts) => {
  if (!instance) {
    console.error("MSAL instance is not available");
    return null;
  }

  if (!accounts || accounts.length === 0) {
    console.error("No accounts available");
    return null;
  }

  const account = accounts[0];

  try {
    // Attempt to acquire token silently
    const response = await instance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    return response.accessToken;
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Silent acquisition failed, attempt interactive acquisition
      try {
        const response = await instance.acquireTokenPopup({
          ...loginRequest,
          account: account,
        });
        return response.accessToken;
      } catch (popupError) {
        console.error("Token acquisition via popup failed:", popupError);
        return null;
      }
    } else {
      console.error("Silent token acquisition failed:", error);
      return null;
    }
  }
};

/**
 * Makes an authenticated API call using the provided access token.
 * @param {string} accessToken - The access token for authorization.
 * @param {string} apiEndpoint - The API endpoint to call.
 * @param {object} [options={}] - Optional configurations for the API call.
 * @returns {Promise<any|null>} - A promise that resolves to the API response data or null.
 */
export const fetchSecure = async (accessToken, apiEndpoint, options = {}) => {
  if (!accessToken) {
    console.error("Access token is not available");
    return null;
  }

  try {
    const response = await axios({
      method: options.method || "GET",
      url: apiEndpoint,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...options.headers,
      },
      data: options.data || null,
      params: options.params || null,
    });
    return response.data;
  } catch (error) {
    console.error("API call failed:", error);
    return null;
  }
};
