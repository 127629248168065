import { useState } from 'react';

const ModalDialog = ({
    title,
    children,
    showModal,
    handleClose,
    handleOk,
    okLabel = 'Ok',
    closeLabel = 'Close',
    isOk = true
  }) => {
    return (
      <>
        {/* Modal Dialog */}
        {showModal && (
          <div className="modal modal-xl fade show d-block" tabIndex="-1">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">{title}</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={handleClose}
                  ></button>
                </div>
                <div className="modal-body">{children}</div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                  >
                    {closeLabel}
                  </button>
                  {isOk && (
                    <button type="button" className="btn btn-primary" onClick={handleOk}>
                      {okLabel}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

export default ModalDialog;
