// src/AuthContext.js

import { createContext } from "react";
import { useMsal } from "@azure/msal-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { instance, accounts } = useMsal();

  return (
    <AuthContext.Provider value={{ instance, accounts }}>
      {children}
    </AuthContext.Provider>
  );
};
